var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav-links"},[_c('ul',{staticClass:"nav-links__list"},_vm._l((_vm.items),function(item,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
        'nav-links__item',
        {
          'nav-links__item--has-submenu': item.submenu,
          'nav-links__item--hover': item === _vm.hoveredItem,
        },
      ],on:{"mouseenter":function($event){return _vm.onItemMouseEnter(item)},"mouseleave":function($event){return _vm.onItemMouseLeave(item)}}},[_c('AppLink',{staticClass:"nav-links__item-link",attrs:{"to":item.url}},[_c('div',{staticClass:"nav-links__item-body"},[_vm._v("\n          "+_vm._s(item.name)+"\n          "),(item.submenu)?_c('ArrowRoundedDown9x6Svg',{staticClass:"nav-links__item-arrow"}):_vm._e()],1)]),_vm._v(" "),(item.submenu && item.submenu.type === 'menu')?_c('div',{ref:"submenus",refInFor:true,class:[
          'nav-links__submenu',
          'nav-links__submenu--type--menu',
          {
            'nav-links__submenu--display': item === _vm.hoveredItem,
            'nav-links__submenu--open': item === _vm.hoveredItem,
          },
        ]},[_c('Menu',{attrs:{"items":item.submenu.menu},on:{"itemClick":function($event){return _vm.onSubmenuItemClick(item)}}})],1):_vm._e(),_vm._v(" "),(item.submenu && item.submenu.type === 'megamenu')?_c('div',{ref:"submenus",refInFor:true,class:[
          'nav-links__submenu',
          'nav-links__submenu--type--megamenu',
          `nav-links__submenu--size--${item.submenu.menu.size}`,
          {
            'nav-links__submenu--display': item === _vm.hoveredItem,
            'nav-links__submenu--open': item === _vm.hoveredItem,
          },
        ]},[_c('Megamenu',{attrs:{"menu":item.submenu.menu,"location":"nav-panel"}})],1):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }