

import { Vue, Component, Prop } from 'vue-property-decorator'
import { IProduct } from '~/interfaces/product'
import Rating from '~/components/shared/rating.vue'
import ProductGallery from '~/components/shared/product-gallery.vue'
import AppLink from '~/components/shared/app-link.vue'
import AsyncAction from '~/components/shared/async-action.vue'
import InputNumber from '~/components/shared/input-number.vue'
import Wishlist16Svg from '~/svg/wishlist-16.svg'
import Compare16Svg from '~/svg/compare-16.svg'

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    components: { Rating, AppLink, ProductGallery, AsyncAction, Wishlist16Svg, Compare16Svg, InputNumber }
})
export default class Product extends Vue {
    @Prop({ type: String, required: true }) readonly layout!: ProductLayout
    @Prop({ type: Object, required: true }) readonly product!: IProduct

    quantity: number | string = 1

    addToCart (): Promise<void> {
        if (typeof this.quantity === 'string' || this.quantity < 1) {
            return Promise.resolve()
        }

        return this.$store.dispatch('cart/add', { product: this.product, quantity: this.quantity })
    }

    getYoutubeEmbed(param) {
        if (param) {
            const split = param.split("=")
            if (split && split.length > 0) {
                let [_, arg] = [null, split[1]]
                return `https://www.youtube.com/v/${arg}`
            }
        }
    }
}

