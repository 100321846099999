import api from './api'

export default {
    add(params) {
        return api.post('header_banners', params)
    },
    async get(id) {
        const res = await api.fetch(`header_banners/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('header_banners', params)
        return res
    },
    update(params) {
        return api.patch('header_banners', params)
    },
    delete(id) {
        return api.remove('header_banners', id)
    }
}