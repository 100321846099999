import api from './api';

export default {
  add(params) {
    return api.post('section_banners', params);
  },
  async get(id) {
    const res = await api.fetch(`section_banners/${id}`);
    return res;
  },
  async list(params) {
    const res = await api.fetch('section_banners', params);
    return res;
  },
  update(params) {
    return api.patch('section_banners', params);
  },
  delete(id) {
    return api.remove('section_banners', id);
  },
};
