import api from './api'

export default {
    add(params) {
        return api.post('brands', params)
    },
    async get(id) {
        const res = await api.fetch(`brands/${id}`)
        return res
    },
    async list(params) {
        const res = await api.fetch('brands', params)
        return res
    },
    update(params) {
        return api.patch('brands', params)
    },
    delete(id) {
        return api.remove('brands', id)
    }
}